<template>
	
	
  <div
    class="m-cart"
    :style="'--aog-totalprice:\'' + formatMoney(totalPrice / 100) + '\''"
  >
    <div class="my-cart">
      <template v-if="cart.length > 0">
        <div class="header-menu">
          <span @click="goBack()"><i class="iconfont icon-left-02"/>返回首頁</span>
          <span
            class="btn"
            @click="
              showDelete = !showDelete;
              selectAll = false;
            "
            >{{ showDelete ? PAGELANG.complete : PAGELANG.manage }}</span
          >
        </div>
        <div class="header-menu-placeholder"></div>

<!--        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :pulling-text="SYSLANG.下拉即可刷新"
          :loosing-text="SYSLANG.释放即可刷新"
          :loading-text="SYSLANG.loading"
        > -->

		
		<van-list
		   v-model:loading="loading"
		   :finished="finished"
		   :finished-text="SYSLANG.finished"
		   :loading-text="SYSLANG.loading"
		   @load="getCart"
		   :style="'--goods-lose-efficacy:\'' + PAGELANG.loseefficacy + '\''"
		 >
		  <div class="controls">
		               <div  v-if="totalPrice>=4600000" style="width: 100%;display:flex;margin-top:10px;height:50px;">
					     <div style="display: flex; width:100%;align-items: center;">
							 <div class="color-main fw"  style="display:flex;align-items:center;width:150px;">是否参与锦鲤活动</div>
							 <div> 
							  <van-switch
							    v-model="luck"
							    size="20"
							    active-color="#66dca0"
							    active-value="1"
							    inactive-value="0"
								@change="switchHandle()"
							   />
							 </div> 
						 </div>
		               </div>
					   <div  v-if="totalPrice>=4600000"  style="border:1px dashed #000; width:200px; margin:10px 0px;"></div>
					   <div  v-if="totalPrice>=690000" style="width: 100%;display:flex;margin-top:10px;">
					           <div style="display: flex; width: 100%;align-items: center;">
					             <div class="color-main fw" style="display:flex;align-items:center;width:150px;">是否使用混選套餐</div>
								 <div>
									<van-switch
									  v-model="luck2"
									  size="20"
									  active-color="#66dca0"
									  active-value="1"
									  inactive-value="0"
									  @change="switchHandle2()"
									 /> 
								 </div>
					           </div>
							   <div style="margin-bottom:10px;">
							      <div style="color: #0d3b4b !important;">可用次数{{luckyCharmOrderStatus}}/4次</div>
							   </div>
					   
					   
					   </div>
		  </div>
		  <view class="page-body">
			  <view class="page-left">
			  			<view  v-for="(item, index) in cart" :key="index">
			  			  <view style="display:flex;justify-content: space-around;height:100px;align-items: center;">
							  <van-checkbox
								v-model="item.checked"
								@click="onProductChanged(index)"
								
							  >
							  </van-checkbox>
							  <view class="name"  @click="onProductChange(index)">
								{{item.productName}}
							  </view>
						  </view>
			  			</view>
			  </view>	
			  <view class="page-right">
				  <div v-for="(item, index) in cartList" :key="index" class="cart-item">
				    <van-swipe-cell class="content">
				      <template #default>
				        <div style="display: flex; width: 100%; height: 100px">
				          <van-checkbox
				            v-model="item.checked"
				            @click="onSkuChange(item)"
				            :disabled="item.Status == 0"
				          >
				            <van-image
				              width="70"
				              height="70"
				              radius="8"
				              :src="item.thumb"
				              fit="cover"
				            />
				          </van-checkbox>
				            <div class="info">
								<div
								  class="title"
								  :style="
									item.skuName.length <= 0
									  ? '-webkit-line-clamp: 2'
									  : ''
								  "
								  @click="viewGoods(item.productId)"
								>
								  {{ item.productName }}
								</div>
								<div v-if="item.categoryName=='组合系列B' || item.categoryName=='组合系列C' ||  item.categoryName=='组合系列A'" style="margin-top:5px;color:red;font-size:14px;">{{item.categoryName}}商品 </div>
								
				               <div class="props">{{ item.propertyValues }}</div>
							   <div><span v-if="item.taiWanStock==0" style="font-size: 12px; color: red;">庫存不足</span></div>
				            </div>
				        </div>
						<div class="price">
						  <span
						    ><label class="currency">{{ SYSLANG.currency }}</label
						    >{{item.retailPrice/100}}</span
						  >
						  <van-stepper
						    v-if="item.status==1"
						    v-model="item.cartQuantity"
						    min="1"
						    :max="item.taiWanStock"
						    integer
						    theme="round"
						    button-size="22"
						    @change="calculateTotalPrice(item)"
						    @blur="calculateTotalPrice(item)"
						  />
						</div>
				      </template>
				      <template #right>
				        <van-button
				          square
				          :text="PAGELANG.delete"
				          type="danger"
				          class="delete-button"
				          @click="onDelete(item.skuId)"
				        />
				      </template>
				    </van-swipe-cell>
				  </div>
			  </view>  
		  </view> 
		 
		 </van-list>
		  

        <!-- </van-pull-refresh> -->
      </template>
      <van-empty
        v-else
        :image="require('@/assets/images/notdata_cart.png')"
        image-size="180"
        :description="PAGELANG.购物车空空如也"
      >
        <van-button
          round
          type="primary"
          icon="aog1 iconfont icon-cart-02"
          to="./product"
          block
          :replace="true"
          class="btn-buynow"
          color="linear-gradient(to right, #e4c994, #cdaa66)"
          >{{ PAGELANG.马上选购 }}</van-button
        >
      </van-empty>
    </div>

    <template v-if="cart.length > 0">
      <van-submit-bar
        v-if="!showDelete"
        :decimal-length="0"
        :placeholder="true"
        :price="totalPrice"
        :currency="SYSLANG.currency"
        :button-text="PAGELANG.submit"
        :label="SYSLANG.amountto + ': '"
        button-color="linear-gradient(to right, #e4c994, #cdaa66)"
        @submit="onSubmit"
      >
        <van-checkbox
          v-model="selectAll"
          @click="checkAll"
          checked-color="var(--van-card-price-color)"
          >{{ PAGELANG.selectall }}</van-checkbox
        >
      </van-submit-bar>
      <van-submit-bar
        v-else
        :placeholder="true"
        :button-text="PAGELANG.delete"
        :price="totalPrice"
        button-color="linear-gradient(to right, #f47d7d, #ce1c1c)"
        @submit="onDelete()"
        class="delete-bar"
      >
        <van-checkbox
          v-model="selectAll"
          @click="checkAll"
          checked-color="var(--van-card-price-color)"
          >{{ PAGELANG.selectall }}</van-checkbox
        >
      </van-submit-bar>
    </template>
  </div>


</template>

<script>
import {
  showConfirmDialog,
  showToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      cart: [],
      refreshing: false,
      finished: false,
      loading: false,
      curpage: 0,
      pagecount: 0,
      selectAll: false,
      showDelete: false,
      totalPrice: 0,
	  productIdx:0,
	  cartList:[],
	  luckCharmOrderStatus:0,
	  luckCharmOrderType:0,
	  luck:0,//錦鯉
	  luck2:0,//混選
	  couponNumber:0,
	  ischeckAll:false,
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios
	  .post(this.actions.getCodeList,{
	    page:1,
	    status:0,
		pageSize:100,
	  })
	  .then((res) => {
		  console.log(res.data.data)
		  this.couponNumber=res.data.data.total
		  this.couponList=res.data.data
	  })
	this.axios
	  .post(this.actions.luckyStatus,{
	    luckyCharmOrderStatus:2,
	  })
	  .then((res) => {
		  console.log(res.data.data)
		  this.luckyCharmOrderStatus=4-res.data.data.luckCharmOrderStatus
	  })  
	  

    this.PAGELANG = this.LANG.pages.cart;
    this.GOODSLANG = this.LANG.pages.goods;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    this.getCart();
  },
  methods: {
	  
	onConfirm() {
	  // 确认操作
	  console.log('确认');
	},
	onCancel() {
	  // 取消操作
	  console.log('取消');
	},  
	  
	goBack(){
		 //this.$router.back()
		 this.$router.replace({
			 path: "/",
		 })
	},  
    //錦鯉
	switchHandle(){
	   console.log(this.luck)
	   if(this.luck==1){
	   		  this.luck2=0
	   }
	
	},
	switchHandle2(){
	   console.log(this.luck2)
	   if(this.luck2==1){
		  this.luck=0
	   }

	},
    formatMoney(money) {
		return money;
    },
    onRefresh() {
       this.cart = [];
       this.loading = false;
       this.finished = false;
       this.curpage = 0;
       this.getCart();
    },
    getCart() {
      if (this.refreshing) {
        this.refreshing = false;
      }
    showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.loading = true;
      this.finished = true;
      this.curpage++;
      this.axios
        .get(this.actions.cartList, {
          curpage: this.curpage,
        })
        .then((response)=> {
          let { code, message, data } = response.data;
		  console.log('data',data)
          if (code == 200) {
            closeToast();
            //let { curpage, pagecount } = data;

            // // 读取购物车数据
            // for (let i = 0; i < data.length; i++) {
            //   if (!data[i].selected) {
            //       data[i].selected = false;
            //   }
            // }
            console.log("cart", data);

            //this.cart = this.cart.concat(data);
			data.map(item=>{
				 item.checked=false
				 item.check=0
				 item.list.map(item2=>{
					 item2.checked=false
					 item2.check=0
				 })
			})
			this.cart=data
			this.cartList=data[0].list
            //this.curpage = curpage * 1;
            //this.pagecount = pagecount * 1;
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.loading = false;
          this.finished = false;

          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          console.log("views.Cart.getCart.error", error);
          closeToast();
          this.curpage--;
        });
    },
    viewGoods(goodsid) {
      this.$router.push({
        path: "/product/detail",
        query: {
          GoodsID: goodsid,
        },
      });
    },
    checkAll() {
	  console.log('this.cart',this.cart)
      for (let i = 0; i < this.cart.length; i++) {
        this.cart[i].checked = this.selectAll;
		this.cart[i].list.map(item=>{
			  if(this.ischeckAll==false){
				  item.checked=true
				  item.check=1
			  }else{
				  item.checked=false
				  item.check=0  
			  }
			  // if(item.check==1){
			  // 					item.checked=false
			  // 					item.check=0 
			  // }else{
			  // 					 item.checked=true
			  // 					 item.check=1
			  // }
		})
      }
	  if(this.ischeckAll==false){
		  this.ischeckAll=true
		  
	  }else{
		  this.luck2=0
		  this.luck=0
		  this.ischeckAll=false
	  }
      this.calculateTotalPrice();
    },
	onProductChange(event){
		console.log(event)
		this.productIdx=event;
		this.cartList=this.cart[event].list
	},
	//點擊sku
	onSkuChange(event){
		console.log('event',event);
		let list = this.cart.reduce((arr, store) => {
		      console.log(arr, store)
		      return [...arr, ...store.list]
		    }, [])
		console.log('list',list);
		let skuId=event.skuId
		list.map((item)=>{
			 if(item.skuId==skuId){
				 if(item.check==1){
					item.checked=false
					item.check=0 
				 }else{
					 item.checked=true
					 item.check=1
				 }
			 }
		})
		let totalPrice = 0;
		list.map((item)=>{
			 if(item.check==1){
						 totalPrice +=
						 	    parseInt(item.retailPrice * 1) *
						 	    parseInt(item.cartQuantity);
			 }
		})
		this.totalPrice = totalPrice;
		this.cart.map((item,index)=>{
		     console.log('item',item)
			 if(item.list.every((item)=>item.check==1)==true){
				 this.cart[index].checked=true
				 this.cart[index].check=1
			 }else{
				 this.cart[index].checked=false
				 this.cart[index].check=0
			 }
		})
	},
	//左邊商品切換
	onProductChanged(event){
		console.log(event)
		this.productIdx=event;
		this.cartList=this.cart[event].list
		console.log('event',event);
		console.log('cart',this.cart)
		if(this.cart[event].check==1){
		   this.cart[event].checked=false
		   this.cart[event].check=0
		   this.cart[event].list.map((item)=>{
		   	 item.checked=false
			 item.check=0
		   })
		}else{
			this.cart[event].checked=true
			this.cart[event].check=1
			this.cart[event].list.map((item)=>{
				 item.checked=true
				 item.check=1
			})
		}
		let list = this.cart.reduce((arr, store) => {
		      console.log(arr, store)
		      return [...arr, ...store.list]
		    }, [])
		console.log('list',list)
		let totalPrice = 0;
		list.map((item)=>{
			 if(item.check==1){
						 totalPrice +=
						 	    parseInt(item.retailPrice * 1) *
						 	    parseInt(item.cartQuantity);
			 }
		})
		this.totalPrice = totalPrice;
	},
    calculateTotalPrice(index = -1) {
      if (this.showDelete) {
        return;
      }
	  let list = this.cart.reduce((arr, store) => {
	        console.log(arr, store)
	        return [...arr, ...store.list]
	      }, [])
	  console.log('list',list)
	  
	  
	  let totalPrice = 0;
	  
	  list.map((item)=>{
	  	 if(item.check==1){
	  				 totalPrice +=
	  				 	    parseInt(item.retailPrice * 1) *
	  				 	    parseInt(item.cartQuantity);
	  			           
	  	 }
	  })
	  this.totalPrice = totalPrice;
      console.log('index',index)
        // 更新购物车
        this.axios
          .post(this.actions.resetSku, {
            skuId: index.skuId,
			type:"take",
            quantity: index.cartQuantity,
          })
          .then(({ data }) => {
            let { code, msg } = data;
            if (code != "200") {
              console.log("更新购物车失败", msg);
            }
          })
          .catch((error) => {
            console.log("views.Cart.calculateTotalPrice.error", error);
          });
    },
    //刪除購物車中商品
    onDelete() {
      let dataSkus=[];
      let list = this.cart.reduce((arr, store) => {
            console.log(arr, store)
            return [...arr, ...store.list]
          }, [])
      console.log('list',list)
      list.map((item)=>{
      	 if(item.check==1){
      			 item.quantity=item.cartQuantity
      		     dataSkus.push(item.skuId)
      			           
      	 }
      })
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteselected,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          // 确认删除
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .post(this.actions.removeSku, {
              skuIds:dataSkus,
			  type:"take",
            })
            .then((response) => {
              closeToast();
              console.log(response);
              let { code, message } = response.data;
              if (code==200) {
                showToast({
                  message:message,
                  onClose: () => {
                    this.onRefresh();
                  },
                });
              } else {
                showToast(
                  code == "NOTLOGIN" ? this.SYSLANG[message] : "刪除失敗"
                );
              }
            })
            .catch((error) => {
              console.log("views.Cart.onDelete.error", error);
            });
        })
        .catch(() => {});
    },
    //提交訂單
    onSubmit(val) {
	  showLoadingToast({
		   message: this.SYSLANG.loading,
		   duration: 0,
		   forbidClick: true,
	  });	
      console.log(val);
	  console.log(this.luck2)
	  let dataSkus=[];
	  let list = this.cart.reduce((arr, store) => {
	        console.log(arr, store)
	        return [...arr, ...store.list]
	      }, [])
	  console.log('list',list)
	  list.map((item)=>{
	  	 if(item.check==1){
			 item.quantity=item.cartQuantity
	  		 dataSkus.push(item)
	  			           
	  	 }
	  })
	  if(dataSkus.length==0){
		  showToast("請選擇商品下單");
		  return;
	  }
	  console.log('this.luck2',this.luck2)
	  console.log('this.luck',this.luck)
	  if(this.luck2==1){
		this.luckCharmOrderType=2
	  }
	  if(this.luck==1){
	    this.luckCharmOrderType=1
	  }
	  if(this.luck==0 && this.luck2==0){
		  this.luckCharmOrderType=0
	  }
	
	
	//六周年
	let sex=[]
	let sixArr=[]
	console.log('couponList',this.couponList)
	if(this.couponList.list.length>0){
		console.log('123123')
		sex=this.couponList.list.filter((item) => item.codeName=='六周年限量特典礼兑换券')
		sixArr=dataSkus.filter((item) => item.productId=="03c7b79967ab43beb7e9398b330e4446")
		console.log("sixArr",sixArr)
		if(sex.length>0 && sixArr.length<=0 ){
		      showToast('请先下单六周年限量特典礼')
		      return;
		}
		if(sex.length>0 && sixArr[0].quantity!=1 ){
		      showToast('六周年限量特典礼兑换券数量不匹配')
		      return;
		}
	}
	if(sex.length<=0 && sixArr.length>0 ){
	   showToast('六周年纪念礼盒需要充值才可加入购物车详情请咨询客服')
	   return;
    }
	
	let selectArr=[]
	selectArr=dataSkus.filter((item) => item.productId=="b76f11b09a5845a9a9e907787b43e501")
	if(sixArr.length>0 && dataSkus.length!=sixArr.length){   
	      showToast('六周年限量特典礼兑换券需单独下单结算')
	      return
    }
	if(selectArr.length>0 && dataSkus.length!=selectArr.length){   
	      showToast('六周年限定版内衣需单独下单结算')
	      return
    }

	
	
	  
	//筛选赠品
	let activity=[] //拆分赠品 束身衣
	let activity2=[]  
	let activity3=[] //2319大胸
	let activity4=[] //六周年贈品/手冊
	activity=dataSkus.filter((item) =>  item.productId=='2d8687ce6cc347bcb33dd9b487b29aee' || item.productId=='b5b86fbef1af4faf9a67bd78778106ce' || item.productId=='ed59b85637a24dfba448fc725dc777bc')
	activity2=dataSkus.filter((item) => (item.productId!='b601aaa35f9a4991b9fd2d6b4116890f' && item.productId!='2d8687ce6cc347bcb33dd9b487b29aee' && item.productId!='b5b86fbef1af4faf9a67bd78778106ce' && item.productId!='ed59b85637a24dfba448fc725dc777bc'))
	activity3=dataSkus.filter((item) => item.productId=='b601aaa35f9a4991b9fd2d6b4116890f')
	activity4=dataSkus.filter((item) => item.productId=='03c7b79967ab43beb7e9398b330e4446' || item.productId=='b76f11b09a5845a9a9e907787b43e501')
    
	
	console.log('activity3',activity3)
	if(activity.length>0 && activity3.length>0){
		  showToast("兑换塑身衣暂时无法下单");
		  return;
	}
	if(activity.length>0 && activity2.length>0){
		  showToast("兑换塑身衣只能单独下单");
		  return;
	}
	  
	if(activity3.length>0 && activity.length>0){
		showToast("兑换商品只能单独下单");
		return;
	}
	if(activity3.length>0 && activity2.length>0){
		showToast("兑换商品只能单独下单");
		return;
	}
	
		  
	//組合商品邏輯  
	    let zuheA=[] //组合A
	    let zuheB=[] //组合B
	    let zuheC=[] //组合C

	
	 zuheC= dataSkus.filter((item) => item.productId=="37b5c805fafa4f24a68b0deffa20318c" || item.productId=="01864ca954a541e58aa5de6c76fe6d24")
	 zuheA= dataSkus.filter((item) => item.productId=="a8ebae5b6a694ebe94109b34de9eb0f2" || item.productId=="dd16c615fa864f6f9cff6177d871c724" || item.productId=="cf173cefdf954e2fb5f78a4618c2ac1c")
	 zuheB= dataSkus.filter((item) => item.productId=="8e9e4c0f3dc1423288e9a9d7394358ac" || item.productId=="c9f54c2c413a475db5c95e8f1cbfadcc")
	 
	let zuheAnumber=0
	let zuheBnumber=0
	let zuheCnumber=0

	
	zuheA.map((item)=>{ zuheAnumber=zuheAnumber+item.quantity})
	zuheB.map((item)=>{ zuheBnumber=zuheBnumber+item.quantity})
	zuheC.map((item)=>{ zuheCnumber=zuheCnumber+item.quantity})

	
	
	let selectArr2 = dataSkus.filter((item) => item.isOpenToBooking=='1') //判断是否是台湾用户 有排单
	
	
	
	console.log('zuheCnumber',zuheCnumber)
	/*
    if(zuheAnumber>=1 || zuheBnumber>=1 || zuheCnumber>=1){
      if(zuheAnumber%10!=0 || zuheBnumber%10!=0 || zuheCnumber%10!=0){
        showToast('下單組合商品 單個組合必須要滿足10的倍數')
        return;
      }
	  if(dataSkus.length!=(zuheA.length+zuheB.length+zuheC.length)){
	    showToast('組合商品不可與普通商品一起下單')
	    return
	  }

    }
	*/
	if(zuheAnumber>=1){
       if(zuheAnumber%15!=0){
              showToast('买10送5必须要满足十五的倍数')
              return;
            }
            if(dataSkus.length!=zuheA.length){
              showToast('10送5必须单独下单')
              return
            }		
	}

	
	
	
	
	    let resultant=0 
		
		
		
		/*
		
	    let sexActivty=[] //拆分1688 内衣
	    let sexActivty2=[] //拆分1688 裤子
	
	    sexActivty=dataSkus.filter((item) => item.productId=='bc8ed9c14d9a453db372101cdc908343') //衣服
	    sexActivty2=dataSkus.filter((item) => item.productId=='fba43dd6c38740d4b1aa97e56e7bbb15') //褲子
	    
	    let sexActivtyNumber=0
	    let sexActivty2Number=0
	 
	   
	
	    sexActivty.map((item)=>{ sexActivtyNumber=sexActivtyNumber+item.quantity})
	    sexActivty2.map((item)=>{ sexActivty2Number=sexActivty2Number+item.quantity})
	
	
	  
	  
	    if(sexActivtyNumber>0 && sexActivtyNumber%10!=0){
	      showToast('限制购买数量必须要满足十的倍数')
	      return;
	    }
	    if(sexActivtyNumber==0 &&  sexActivty2Number%10!=0 ){
	      showToast('限制购买数量必须要满足十的倍数')
	      return;
	    }
	    if(sexActivtyNumber>0 && sexActivtyNumber%10==0){
	      if(sexActivty2Number%10!=0 &&  sexActivty2Number%6!=0 ){
	        showToast('限制购买数量必须要满足十的倍数')
	        return;
	      }
	      if(sexActivty2Number>0 && sexActivty2Number%6==0){
	         if(sexActivty2Number/6!=sexActivtyNumber/10){
	           showToast('限制购买数量必须要满足十的倍数')
	           return;
	        }else{
	          if(dataSkus.length!=(sexActivty.length+sexActivty2.length)){
	            showToast('参与1688活动只能单独下单')
	            return;
	          }
	          resultant=sexActivty2Number/6
	        }
	      }else{
	         resultant=0
	      }
	    }
		*/
	
	
    
	
	if(activity4.length>0){
		  this.axios
		    .post(this.actions.checkOrderCalc, {
		           skus:activity4,
		  		   addressId:"",
		  		   deductionSkus:activity4,
		  		   luckCharmOrderStatus:this.luckCharmOrderType,
		  		   type:"take"
		    })
		    .then((response) => {
		      closeToast();
		      let { code, message } = response.data;
		      if (code == "200") {
		  			this.$router.push({
		  			  name: "placeorder",
		  			  state: {
		  			    skus:JSON.stringify(activity4),
		  				deductionSkus:JSON.stringify(activity4),
		  				luckCharmOrderStatus:this.luckCharmOrderType,
		  			    isfromcart: true,
						activity4:JSON.stringify(activity4)
		  			  },
		  			});
		      } else {
		        showToast(
		          code != 200 ? message : message
		        );
		      }
		    })
			return
	  }
	
	
	if(activity3.length>0){
		  let number=0 
		  activity3.map(item=>{
			  number=item.quantity+number
		  })
		  console.log(number)
		  activity3[0].spuProductId=activity3[0].productId
		  if(number!=4){
			  showToast("商品數量於兌換劵數量不匹配請修改數量重新下單");
			  return;
		  }
		  this.axios
		    .post(this.actions.checkOrderCalc, {
		           skus:activity3,
		  		   addressId:"",
		  		   deductionSkus:activity3,
		  		   luckCharmOrderStatus:this.luckCharmOrderType,
		  		   type:"take"
		    })
		    .then((response) => {
		      closeToast();
		      let { code, message } = response.data;
		      if (code == "200") {
		  			this.$router.push({
		  			  name: "placeorder",
		  			  state: {
		  			    skus:JSON.stringify(activity3),
		  				deductionSkus:JSON.stringify(activity3),
		  				luckCharmOrderStatus:this.luckCharmOrderType,
		  			    isfromcart: true,
						activity3:JSON.stringify(activity3)
		  			  },
		  			});
		      } else {
		        showToast(
		          code != 200 ? message : message
		        );
		      }
		    })
			return
	  }
	
	
	  
	  //下单赠品
	if(activity.length>0){
		  let number=0 
		  activity.map(item=>{
			  number=item.quantity+number
		  })
		  console.log(number)
		  if(number%5!=0){
			  showToast("塑身衣數量於兌換劵數量不匹配請修改數量重新下單");
			  return;
		  }
		  this.axios
		    .post(this.actions.checkOrderCalc, {
		           skus:activity,
		  		   addressId:"",
		  		   deductionSkus:activity,
		  		   luckCharmOrderStatus:this.luckCharmOrderType,
		  		   type:"take"
		    })
		    .then((response) => {
		      closeToast();
		      let { code, message } = response.data;
		      if (code == "200") {
		  			this.$router.push({
		  			  name: "placeorder",
		  			  state: {
		  			    skus:JSON.stringify(activity),
		  				deductionSkus:JSON.stringify(activity),
		  				luckCharmOrderStatus:this.luckCharmOrderType,
		  			    isfromcart: true,
						activity2:JSON.stringify(activity2)
		  			  },
		  			});
		      } else {
		        showToast(
		          code != 200 ? message : message
		        );
		      }
		    })
	  }else{
		  closeToast();
		  if(selectArr2.length>0){
			   let arr=[]
			   selectArr2.map((item)=>{ arr.push(item.openToBookingDate)})
			   let arr2=(Math.max.apply(null, arr));
			   console.log(arr2)
			   let datearr=String(arr2).split('.')
			   console.log('datearr',datearr)
			   showConfirmDialog({
			     title:'提醒',
			     message:'您選購的訂單包含[排單]商品,預計於'+ datearr[0]+'月'+datearr[1]+'日起排單發貨,詳情請咨詢客服',
			     confirmButtonText:"確認",
			     cancelButtonText:"取消",
			   })
			     .then(() => {
			       this.axios
			         .post(this.actions.checkOrderCalc, {
			               skus:activity2,
			       		   addressId:"",
			       		   deductionSkus:activity,
			       		   luckCharmOrderStatus:this.luckCharmOrderType,
			       		   type:"take",
						   resultant:resultant
			         })
			         .then((response) => {
			           closeToast();
			           let { code, message } = response.data;
			           if (code == "200") {
			       			this.$router.push({
			       			  name: "placeorder",
			       			  state: {
			       			    skus:JSON.stringify(activity2),
			       				deductionSkus:activity,
			       				luckCharmOrderStatus:this.luckCharmOrderType,
			       			    isfromcart: true,
								resultant:resultant
			       			  },
			       			});
			           } else {
			             showToast(
			               code != 200 ? message : message
			             );
			           }
			         })
			  
			         .catch((error) => {
			           console.log("views.Cart.onDelete.error", error);
			         });
			     })
			     .catch(() => {});
		  }else{
			  this.axios
			    .post(this.actions.checkOrderCalc, {
			           skus:activity2,
			  		   addressId:"",
			  		   deductionSkus:activity,
			  		   luckCharmOrderStatus:this.luckCharmOrderType,
			  		   type:"take",
					   resultant:resultant
			    })
			    .then((response) => {
			      closeToast();
			      let { code, message } = response.data;
			      if (code == "200") {
			  			this.$router.push({
			  			  name: "placeorder",
			  			  state: {
			  			    skus:JSON.stringify(activity2),
			  				deductionSkus:activity,
			  				luckCharmOrderStatus:this.luckCharmOrderType,
			  			    isfromcart: true,
							resultant:resultant
			  			  },
			  			});
			      } else {
			        showToast(
			          code != 200 ? message : message
			        );
			      }
			    })
			  
			  
		  }
	  }


	  
	  
	  
	  
      // let ids = [];
      // for (let i = 0; i < this.cart.length; i++) {
      //   if (this.cart[i].selected) {
      //     ids.push(this.cart[i].ID);
      //   }
      // }

      // if (ids.length <= 0) {
      //   showToast(this.PAGELANG.notselected);
      //   return;
      // }

      // let postdata = [];
      // let spucounts = [];
      // let totalbuycount = 0;
      // for (let i = 0; i < this.cart.length; i++) {
      //   if (this.cart[i].selected && this.cart[i].Status == 1) {
      //     let obj = new Object();
      //     obj.cartid = this.cart[i].ID * 1;
      //     obj.goodsid = this.cart[i].GoodsID * 1;

      //     let pdtsku = JSON.parse(this.cart[i].PdtSKU);
      //     if (pdtsku.length > 0) {
      //       pdtsku = pdtsku[0];
      //     } else {
      //       pdtsku = -1;
      //     }
      //     obj.pdtsku = pdtsku;

      //     obj.buycount = this.cart[i].BuyCount * 1;
      //     totalbuycount += obj.buycount;
      //     if (spucounts[obj.goodsid]) spucounts[obj.goodsid] += obj.buycount;
      //     else spucounts[obj.goodsid] = obj.buycount;

      //     obj.pdtname = this.cart[i].PdtName;
      //     obj.pdtthumb = this.cart[i].PdtThumb;
      //     obj.selpropstext = this.cart[i].SelPropsText;

      //     postdata.push(obj);
      //   }
      // }
      // console.log("cart postdata", postdata);
      // console.log("spucounts", spucounts);

      // let spurulepass = true;

      // spucounts.forEach((itm) => {
      //   if (itm && (itm < 10 || itm % 10 > 0)) spurulepass = false;
      // });

      // if (!spurulepass || totalbuycount < 10 || totalbuycount % 10 > 0) {
      //   showToast(this.PAGELANG.下单单款商品数量必须是10的倍数);
      //   return;
      // }

      // this.$router.push({
      //   name: "placeorder",
      //   state: {
      //     orderdata: JSON.stringify(postdata),
      //     isfromcart: true,
      //   },
      // });
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
	.controls{
	  font-size: 13px;
	  padding: 0 15px;
	  box-sizing: border-box;
	  background: #cfae6d;
	}
	
	
	.luck-switch{
	display: flex;
	justify-content: flex-start;
	flex: 1;
	align-items: center;
	flex-wrap: wrap;
	padding:10px;
	}	
	
  .pc-cart {
    display: none;
  }
  .page-body{
	      display: flex;
	      height: 100%;
  }
  .page-left {
    width:110px;
    min-width: 110px;
    background: #fafafa;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .page-right{
	      flex: 1;
	      background: #fff;
	      overflow-y: scroll;
	      -webkit-overflow-scrolling: touch;
	      position: relative;
  }
  .page-left .name{
       text-align: center;
       display: -webkit-box;
	  -webkit-box-orient: vertical;
	  -webkit-line-clamp: 3;
	  overflow: hidden;
	  max-height: 60px;font-size: 14px;
	  font-weight: bold;
	  margin-right: 3px;
	  max-width:60px;
  }
  
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 44px;
    box-sizing: border-box;
    padding: var(--van-card-padding);
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .header-menu-placeholder {
    height: 44px;
  }
  .my-cart {
    padding: var(--van-card-padding);
    padding-top: 0;
    --van-stepper-button-round-theme-color: var(--van-card-price-color);
    --van-checkbox-checked-icon-color: var(--van-card-price-color);
  }
  .cart-item {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 6px;
    border-bottom: 1px solid #ddd;
  }

  .cart-itme .c {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
  }

  >>> .van-checkbox--disabled .van-image .van-image__img {
    position: relative;
    z-index: 1;
  }
  >>> .van-checkbox--disabled .van-image::after {
    content: var(--goods-lose-efficacy);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .cart-item >>> .van-checkbox__label {
    line-height: 0;
  }
  .cart-item .info {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;

    height: 100%;
    padding: var(--van-checkbox-label-margin);
    box-sizing: border-box;
  }
  .cart-item .price {
    display:flex;
	margin-top:20px;
    justify-content:space-between;
    align-items:center;
  }

  .cart-item  .price > span {
    line-height: 22px;
    font-size: 22px;
    color: var(--van-submit-bar-price-color);
  }
  .cart-item  .price > span .currency {
    font-size: 16px;
  }
  
  


  .cart-item .info .props {
    font-size: 14px;
    color: #555;
  }

  .cart-item .info .title {
    font-size: 16px;
    color: #000;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 24px;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
  }
  .delete-button {
    height: 100%;
  }

  .delete-bar >>> .van-submit-bar__text {
    font-size: 0;
    --van-submit-bar-price-font-size: 0;
    --van-submit-bar-price-integer-font-size: 0;
  }

  .btn-buynow >>> .van-icon-aog {
    color: #fff;
  }

  >>> .van-submit-bar__price-integer {
    font-size: 0;
  }
  >>> .van-submit-bar__price-integer::before {
    content: var(--aog-totalprice);
    font-size: var(--van-submit-bar-price-integer-font-size);
    font-family: var(--van-submit-bar-price-font);
  }
}
@media (min-width: 769px) {
/*  .m-cart {
    display: none;
  } */
  .pc-cart {
    display: none;
  }

  .pc-cart {
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
}
</style>
<style src="../assets/css/cartpc.css"></style>



